import React from "react"
import { Typography } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CleaningCheckList from "../components/CleaningCheckList"

const { Title } = Typography

const WhatsInPage = () => (
  <Layout className="how-it-works">
    <SEO title="What's Included" />
    <div className="page-title">
      <Title>What's Included</Title>
      <Title level={3}>Learn what is included in our home cleaning services</Title>
    </div>
    <section style={{ width: '100%', margin: "auto" }}>
      <CleaningCheckList />
    </section>
  </Layout>
)

export default WhatsInPage
